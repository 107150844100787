<template>
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<h6>{{ $t('sshKey.list.title') }}</h6>			
		</template>
		<a-button  type="primary" slot="extra" size="small" @click="visible = true">{{$t('ticket.sendNewTicket.title')}}</a-button>
		<a-table :columns="columns" :data-source="tickets" :pagination="false">
			<template slot="created" slot-scope="created">
				<span>{{ created | moment("HH:mm DD-MM-YYYY") }}</span>
			</template>
			<template slot="Code" slot-scope="Code,item">
				<router-link :to="{ name: 'tickets.chat', params: { id: item.id }}">#{{Code}}</router-link>
			</template>
		</a-table>
		<a-modal v-model:visible="visible" :title="$t('ticket.ticketOfYou.label')" @ok="handleSubmit">
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
			>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'title',
						{ rules: [{ required: true, message: `${$t('rulesForm.requiredSubjectTicket')}!` }] },
						]"
						:placeholder="$t('ticket.ticketOfYou.subject')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'content',
						{ rules: [{ required: true, message: `${$t('rulesForm.requiredContentTicket')}!` }] },
						]"
						type="textarea"
						:placeholder="$t('ticket.ticketOfYou.detail')"
					>
					</a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</a-card>
</template>

<script>
	import TicketService from '@/services/ticket'

	export default ({
		components: {
		},
		data() {
			return {
				tickets : [],
				columns: [
					{
						title: "Code",
						dataIndex: 'code',
						scopedSlots: { customRender: 'Code' },
					},
					{
						title: this.$t('ticket.ticketOfYou.subject'),
						dataIndex: 'title',
					},
					{
						title: this.$t('ticket.ticketOfYou.created'),
						dataIndex: 'created_at',
						scopedSlots: { customRender: 'created' },
					}
				],
				visible : false,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		created(){
			this.getData()
		},
		methods:{
			async getData(){
				const tickets = await TicketService.listing()
				if(tickets.status == 1){
					this.tickets = tickets.data
				}
			},
			handleSubmit(e){
				e.preventDefault();
				let thiss = this
				this.form.validateFields(async (err, values) => {
					if ( !err ) {
						let formData = new FormData();
						formData.append('title', values.title);
						formData.append('description', values.content);
						await TicketService.create(formData)
						thiss.visible = false
						thiss.getData()
						thiss.form.resetFields()
					}
				});
			}
		}
	})

</script>

<style type="text/css" scoped>
	.ticket{
		background: grey;
		border-radius: 5px;
		color: #fff;
		clear: both;
		float: right;
		max-width: 70%;
		margin: 5px 0;
		padding: 5px 10px;
		width: fit-content;
	}
	.mine{
		background: blue;
		float: left;
	}
</style>